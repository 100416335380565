<template>
  <NuxtLink
    ref="uiHtmlListItemElement"
    no-prefetch
    class="relative -mx-8 flex flex-wrap overflow-hidden"
    :class="{ 'mb-2': props.sizeVariant === 'dense' }"
    :to="slug"
  >
    <UiImage
      v-if="parseImage(props.media?.[0]?.images?.defaultImage || props.media)"
      v-bind="parseImage(props.media?.[0]?.images?.defaultImage || props.media)"
      class="aspect-news-in-list-first-mobile size-full max-h-72 object-cover"
      width="424"
      height="285"
      loading="lazy"
      fetchpriority="low"
    />
    <div class="absolute bottom-0 flex w-full items-end px-8 py-3.5">
      <div
        class="absolute bottom-0 left-0 size-full"
        :class="props.titleBackgroundClass"
      />

      <div class="isolate">
        <p
          class=" line-clamp-3 text-lg font-bold text-white lg:line-clamp-none"
          :class="{ 'mb-2.5': props.sizeVariant !== 'dense' }"
        >
          {{ title }}
        </p>

        <p
          class="flex items-center justify-between gap-2"
          :class="{ hidden: props.sizeVariant === 'dense' }"
        >
          <span class="text-sm text-blue-100"> {{ datetime }} </span>
          <UiTag
            v-if="tag"
            :tag="tag"
            variant="outlet"
          />
        </p>
      </div>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import UiImage from '../../../UiImage/UiImage.vue'
import UiTag from '../../../UiTag/UiTag.vue'
import type { UiNewsItem, UiNewsListItemImage } from '../UiNewsListItem.types'

const props = withDefaults(defineProps<UiNewsItem>(), {
  teaser: '',
  datetime: '',
  tags: () => [],
  categories: () => [],
  readTime: undefined,
  moreLink: '',
  variant: '',
  sizeVariant: 'comfort',
  titleBackgroundClass: 'bg-primary mix-blend-multiply',
})

const uiHtmlListItemElement = ref<HTMLInputElement | null>(null)
defineExpose({
  uiHtmlListItemElement,
})

function parseImage(imageObject: UiNewsListItemImage) {
  if (!imageObject?.publicUrl) { return null }
  return {
    src: imageObject?.publicUrl || '',
    width: imageObject?.dimensions?.width,
    height: imageObject?.dimensions?.height,
  }
}

const tag = computed(() => {
  return props.tags?.[0]?.title ?? ''
})
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>
